// src/components/Sidebar.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarProps {
  onLogout: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  return (
    <div className="w-64 h-full bg-black bg-opacity-75 text-white fixed">
      <div className="p-4">
        <h1 className="text-2xl font-bold">MyApp</h1>
      </div>
      <ul className="mt-4">
        <li className="p-4 hover:bg-blue-600">
          <Link to="/">Home</Link>
        </li>
        <li className="p-4 hover:bg-blue-600">
          <Link to="/members">Members</Link>
        </li>
        <li className="p-4 hover:bg-blue-600">
          <Link to="/tiers">Tiers</Link>
        </li>
        <li className="p-4 hover:bg-blue-600 cursor-pointer" onClick={onLogout}>
          Log out
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;