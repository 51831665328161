// src/pages/MembersPage.tsx
import React from 'react';
import Members from '../components/Members';

const MembersPage: React.FC = () => {
  return (
    <div className="p-4">
      <Members />
    </div>
  );
};

export default MembersPage;