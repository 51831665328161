// src/components/Members.tsx
import React, { useEffect, useState } from 'react';

interface Member {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

const Members: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch('http://localhost:3000/members');
        const data = await response.json();
        setMembers(data);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, []);

  return (
    <div className="w-full max-w-4xl mt-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Members</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {members.map((member, index) => (
          <div key={index} className="p-4 bg-white rounded shadow">
            <h3 className="text-lg font-bold">{member.firstname} {member.lastname}</h3>
            <p className="text-gray-700">Phone: {member.phone}</p>
            <p className="text-gray-700">Email: {member.email}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;