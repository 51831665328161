import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import MembersPage from './pages/MembersPage';
import TiersPage from './pages/TiersPage';
import Sidebar from './components/Sidebar';

interface User {
  username: string;
}

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const username = Cookies.get('username');
    if (username) {
      setUser({ username });
    }
  }, []);

  const handleLogin = (username: string) => {
    setUser({ username });
    Cookies.set('username', username, { expires: 7 }); // Set cookie to expire in 7 days
  };

  const handleLogout = () => {
    setUser(null);
    setDropdownOpen(false);
    Cookies.remove('username');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const username = user?.username || '';

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 text-white flex">
        {user && <Sidebar onLogout={handleLogout} />}
        <div className={`flex-1 ${user ? 'ml-64' : ''}`}>
          {!user && (
            <nav className="bg-black bg-opacity-75 p-4 flex justify-between items-center">
              <div className="text-3xl font-bold">Neighbourhood.me</div>
              <ul className="flex space-x-4">
                <li>
                  <Link to="/" className="text-white hover:underline">Home</Link>
                </li>
                <li>
                  <Link to="/login" className="text-white hover:underline">Login</Link>
                </li>
              </ul>
            </nav>
          )}
          <div className="p-4">
            <Routes>
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="/" element={<HomePage />} />
              {user && <Route path="/members" element={<MembersPage />} />}
              {user && <Route path="/tiers" element={<TiersPage />} />}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;